module.exports = [{
      plugin: require('C:/Users/Chris/Programming/blog_four/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-32000851-2"},
    },{
      plugin: require('C:/Users/Chris/Programming/blog_four/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Chris/Programming/blog_four/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
