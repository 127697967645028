// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\templates\\tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-blog-post-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertising-policy-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\advertising-policy.js" /* webpackChunkName: "component---src-pages-advertising-policy-js" */),
  "component---src-pages-call-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\call.js" /* webpackChunkName: "component---src-pages-call-js" */),
  "component---src-pages-consult-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\consult.js" /* webpackChunkName: "component---src-pages-consult-js" */),
  "component---src-pages-convert-time-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\convert-time.js" /* webpackChunkName: "component---src-pages-convert-time-js" */),
  "component---src-pages-hong-kong-find-taxi-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\hong-kong-find-taxi.js" /* webpackChunkName: "component---src-pages-hong-kong-find-taxi-js" */),
  "component---src-pages-hotel-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-read-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\read.js" /* webpackChunkName: "component---src-pages-read-js" */),
  "component---src-pages-schedule-js": () => import("C:\\Users\\Chris\\Programming\\blog_four\\src\\pages\\schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Chris\\Programming\\blog_four\\.cache\\data.json")

